var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"title":"编辑标签","placement":"right","closable":false,"visible":_vm.show_group,"width":"560"},on:{"close":_vm.returnTagDrawer}},[_c('a-input-search',{attrs:{"placeholder":"请输入","enter-button":"搜索","size":"large"},on:{"search":_vm.onSearch},model:{value:(_vm.nick_name),callback:function ($$v) {_vm.nick_name=$$v},expression:"nick_name"}}),_c('a',{staticStyle:{"display":"block","margin":"12px 0"},on:{"click":_vm.openTips}},[_c('a-icon',{staticStyle:{"margin-right":"4px"},attrs:{"type":"question-circle"}}),_vm._v("找不到群聊")],1),_c('div',[_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('a-button',{attrs:{"type":"primary","disabled":!_vm.hasSelected,"loading":_vm.bat_loading},on:{"click":_vm.addTag}},[_vm._v(" 添加 ")]),_c('span',{staticStyle:{"margin-left":"8px"}},[(_vm.hasSelected)?[_vm._v(" "+_vm._s(("选择 " + (_vm.selectedRowKeys.length) + " 项"))+" ")]:_vm._e()],2),_c('span',{staticStyle:{"float":"right"}},[_vm._v("共"+_vm._s(_vm.pagination.total)+"个群聊")])],1),_c('a-table',{attrs:{"row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      },"columns":_vm.columns,"data-source":_vm.tableList,"scroll":{ y: 340 },"rowKey":function (record, index) { return index; },"loading":_vm.tbl_loading,"pagination":false}},[_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('a',{staticStyle:{"color":"red"},attrs:{"type":"link"}},[_vm._v("删除")])])]),_c('base-pagination',{attrs:{"currentPage":_vm.pagination.current,"pageSize":_vm.pagination.page_size,"total":_vm.pagination.total},on:{"onChange":_vm.onChangePagination,"onShowSizeChange":_vm.onChangePagination}})],1),_c('div',{style:({
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e8e8e8',
      padding: '10px 16px',
      textAlign: 'right',
      left: 0,
      background: '#fff',
      borderRadius: '0 0 4px 4px',
    })},[_c('a-button',{staticStyle:{"margin-right":"8px"},on:{"click":_vm.returnTagDrawer}},[_vm._v(" 返回 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }