<template>
  <a-card>
    <nav class="nav_search">
      <div>
        标签名称:
        <a-input v-model="tag_name" placeholder="请输入" style="width: 210px" />
      </div>
      <div>
        <a-divider type="vertical" style="height: 26px; margin-right: 10px" />
        <a-button type="primary" style="margin-right: 10px" @click="searchList"
          >搜索</a-button
        >
        <a-button style="margin-right: 10px" @click="resetSearch"
          >重置</a-button
        >
        <a-button
          icon="plus"
          type="primary"
          style="margin-right: 10px"
          @click="createTag"
          >创建标签</a-button
        >
      </div>
    </nav>
    <a-table
      :columns="columns"
      :loading="table_loading"
      :pagination="false"
      :rowKey="(record, index) => index"
      :data-source="tableList"
    >
      <div slot="chatrooms" slot-scope="text" v-if="text">
        <div>共{{ text.length }}个群聊</div>
        <div class="group_list">
          <span v-for="item in text" :key="item.id"
            ><span v-if="item.nick_name">{{ item.nick_name }}、</span></span
          >
        </div>
      </div>
      <div slot="action" slot-scope="text, record">
        <a style="padding-right: 15px" @click="openEditTag(text, record)"
          >编辑</a
        >
        <a-divider type="vertical" />
        <a-button
          type="link"
          style="color: red"
          @click="deleteTag(text)"
          :loading="btn_loading"
          >删除</a-button
        >
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
    <!-- 编辑标签 -->
    <edit-tag
      :show_tag="visible_drawer"
      :table_list="group_list"
      :save_loading="save_loading"
      :drawer_title="drawer_title"
      @onClose="closeDrawer"
      @openDrawerGroup="drawerGroup"
      @deleteOneGroup="deleteOneGroup"
      @deleteGroupMore="deleteGroupMore"
      @addNewTag="addNewTag"
      :edit_tag_name="edit_tag_name"
    />
    <add-group
      :show_group="group_drawer"
      @closeGroupDrawer="closeGroupDrawer"
      @addNewGroup="addNewGroup"
    />
  </a-card>
</template>
<script>
import EditTag from "./components/editTag";
import AddGroup from "./components/addGroup";
const columns = [
  {
    title: "标签名称",
    dataIndex: "name",
  },
  {
    title: "标签详情",
    dataIndex: "chatrooms",
    scopedSlots: { customRender: "chatrooms" },
  },
  {
    title: "修改时间",
    dataIndex: "mtime",
  },
  {
    title: "修改人",
    dataIndex: "modifier",
  },
  {
    title: "操作",
    dataIndex: "id",
    scopedSlots: { customRender: "action" },
    width: 160,
  },
];
export default {
  data() {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
      },
      tag_name: "",
      table_loading: false,
      columns,
      tableList: [],
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      btn_loading: false,
      visible_drawer: false,
      group_list: [],
      group_drawer: false,
      save_loading: false,
      tag_id: "",
      drawer_title: "",
      edit_tag_name: "",
    };
  },
  components: {
    EditTag,
    AddGroup,
  },
  mounted() {
    this.getTableList(1, 10);
  },
  methods: {
    getTableList(current, page_size) {
      let params = {
        name: this.tag_name,
        page_num: current,
        page_size,
      };
      this.table_loading = true;
      this.$api.core.GroupChat.getTagList(params)
        .then((res) => {
          if (res.code == 200) {
            this.tableList = res.data.list;
            this.pagination = res.data.pagination;
          }
        })
        .finally(() => {
          this.table_loading = false;
        });
    },
    searchList() {
      this.getTableList(1, 10);
    },
    resetSearch() {
      this.tag_name = "";
      this.getTableList(1, 10);
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getTableList(current, pageSize);
    },
    // 删除标签
    deleteTag(id) {
      this.btn_loading = true;
      this.$api.core.GroupChat.deleteTag(id)
        .then((res) => {
          if (res.code == 200) {
            this.getTableList(1, 10);
          }
        })
        .finally(() => {
          this.btn_loading = false;
        });
    },
    createTag() {
      this.edit_tag_name = "";
      this.drawer_title = "创建标签";
      this.group_list = [];
      this.visible_drawer = true;
    },
    // 添加新群聊
    addNewGroup(value) {
      console.log("value", value);
      let group_arr = this.group_list.concat(value);
      let hash = {};
      let arr2 = [];
      arr2 = group_arr.reduce((preVal, curVal) => {
        hash[curVal.chatroom_id]
          ? ""
          : (hash[curVal.chatroom_id] = true && preVal.push(curVal));
        return preVal;
      }, []);
      this.group_list = arr2;
      this.group_drawer = false;
    },
    deleteOneGroup(id) {
      const screen_arr = this.group_list.filter((item) => {
        return !id.includes(item.chatroom_id);
      });
      this.group_list = screen_arr;
    },
    // 批量删除群聊
    deleteGroupMore(value) {
      const screen_arr = this.group_list.filter((item) => {
        return !value.includes(item.chatroom_id);
      });
      this.group_list = screen_arr;
    },
    cleanObj(obj) {
      let propNames = Object.getOwnPropertyNames(obj);
      for (let i = 0; i < propNames.length; i++) {
        let propName = propNames[i];
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] == ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    // 新增或编辑群聊
    addNewTag(name) {
      this.save_loading = true;
      let chatroom_ids = this.group_list.map(({ chatroom_id }) => chatroom_id);
      let payload = {
        name,
        id: this.tag_id || null,
        chatroom_ids,
      };
      payload = this.cleanObj(payload);
      this.$api.core.GroupChat.createAndEdit(payload)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功");
            this.getTableList(1, 10);
            this.visible_drawer = false;
          }
        })
        .finally(() => {
          this.save_loading = false;
        });
    },
    // 关闭标签抽屉
    closeDrawer() {
      this.visible_drawer = false;
    },
    // 打开群聊抽屉
    drawerGroup() {
      //   this.visible_drawer = false
      this.group_drawer = true;
    },
    // 关闭群聊抽屉
    closeGroupDrawer() {
      this.group_drawer = false;
    },
    // 打开编辑标签
    openEditTag(id, value) {
      this.drawer_title = "编辑标签";
      this.edit_tag_name = value.name;
      this.tag_id = id;
      this.group_list = value.chatrooms || [];
      this.visible_drawer = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.group_list {
  max-width: 460px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>