<template>
  <a-drawer
    :title="drawer_title"
    placement="right"
    :closable="false"
    :visible="show_tag"
    @close="onClose"
    width="580"
  >
    <span>
      标签名称:
      <a-input v-model="tag_name" placeholder="请输入" style="width: 210px" />
    </span>
    <div style="margin: 12px 0">
      群列表如下：<a @click="openGroup"
        ><a-icon type="plus-circle" style="margin-right: 4px" />添加群聊</a
      >
    </div>
    <div>
      <div style="margin-bottom: 16px">
        <a-button
          type="danger"
          :disabled="!hasSelected"
          :loading="b_loading"
          @click="deleteGroupMore"
        >
          删除
        </a-button>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `选择 ${selectedRowKeys.length} 项` }}
          </template>
        </span>
        <span style="float: right">共{{ table_list.length }}个群聊</span>
      </div>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :data-source="table_list"
        :scroll="{ y: 340 }"
        :rowKey="(record, index) => index"
        :loading="t_loading"
        :pagination="false"
        :locale="locale"
      >
        <div slot="action" slot-scope="text">
          <a type="link" style="color: red" @click="deleteOneGroup(text)"
            >删除</a
          >
        </div>
      </a-table>
    </div>
    <div
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }"
    >
      <a-button style="margin-right: 8px" @click="onClose"> 取消 </a-button>
      <a-button type="primary" @click="addNewTag" :loading="save_loading">
        确定
      </a-button>
    </div>
  </a-drawer>
</template>
<script>
import vEmpty from "@/components/v-empty.vue";
const columns = [
  {
    title: "群名称",
    dataIndex: "nick_name",
  },
  {
    title: "群主",
    dataIndex: "chatroom_owner_nick_name",
    width: 160,
  },
  {
    title: "群成员数量",
    dataIndex: "member_count",
  },
  {
    title: "操作",
    dataIndex: "chatroom_id",
    scopedSlots: { customRender: "action" },
    width: 80,
  },
];

export default {
  components: {
    vEmpty,
  },
  props: {
    show_tag: {
      type: Boolean,
      default: false,
      require: true,
    },
    table_list: {
      type: Array,
      default: [],
      require: true,
    },
    save_loading: {
      type: Boolean,
      default: false,
      require: true,
    },
    drawer_title: {
      type: String,
      default: "创建标签",
      require: true,
    },
    edit_tag_name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      columns,
      selectedRowKeys: [],
      tag_name: "",
      t_loading: false,
      b_loading: false,
      locale: {
        emptyText: <vEmpty text="你还没添加任何群聊" />,
      },
      selectedRowArr: [],
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  watch: {
    edit_tag_name: {
      handler: function (value) {
        this.tag_name = value;
      },
    },
  },
  methods: {
    onClose() {
      this.tag_name = "";
      this.$emit("onClose");
    },
    openGroup() {
      this.$emit("openDrawerGroup");
    },
    onSelectChange(selectedRowKeys, record) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowArr = JSON.parse(JSON.stringify(record));
    },
    // 删除单个群
    deleteOneGroup(id) {
      this.$emit("deleteOneGroup", id);
    },
    // 批量删除
    deleteGroupMore() {
      let arr_id = this.selectedRowArr.map(({ chatroom_id }) => chatroom_id);
      this.$emit("deleteGroupMore", arr_id);
      this.b_loading = true;
      setTimeout(() => {
        this.b_loading = false;
        this.selectedRowKeys = [];
        this.selectedRowArr = [];
      }, 500);
    },
    // 确认添加新标签
    addNewTag() {
      if (!this.tag_name) {
        this.$message.error("请添加标签名称！");
        return;
      }
      this.$emit("addNewTag", this.tag_name);
    },
  },
};
</script>