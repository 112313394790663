<template>
  <a-drawer
    title="编辑标签"
    placement="right"
    :closable="false"
    :visible="show_group"
    @close="returnTagDrawer"
    width="560"
  >
    <a-input-search
      v-model="nick_name"
      placeholder="请输入"
      enter-button="搜索"
      size="large"
      @search="onSearch"
    />
    <a style="display: block; margin: 12px 0" @click="openTips"
      ><a-icon type="question-circle" style="margin-right: 4px" />找不到群聊</a
    >
    <div>
      <div style="margin-bottom: 16px">
        <a-button
          type="primary"
          :disabled="!hasSelected"
          :loading="bat_loading"
          @click="addTag"
        >
          添加
        </a-button>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `选择 ${selectedRowKeys.length} 项` }}
          </template>
        </span>
        <span style="float: right">共{{ pagination.total }}个群聊</span>
      </div>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :data-source="tableList"
        :scroll="{ y: 340 }"
        :rowKey="(record, index) => index"
        :loading="tbl_loading"
        :pagination="false"
      >
        <div slot="action">
          <a type="link" style="color: red">删除</a>
        </div>
      </a-table>
      <!-- 分页配置 -->
      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="onChangePagination"
        @onShowSizeChange="onChangePagination"
      >
      </base-pagination>
    </div>
    <div
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }"
    >
      <a-button style="margin-right: 8px" @click="returnTagDrawer">
        返回
      </a-button>
    </div>
  </a-drawer>
</template>
<script>
const columns = [
  {
    title: "群名称",
    dataIndex: "nick_name",
  },
  {
    title: "群主",
    dataIndex: "chatroom_owner_nick_name",
    width: 160,
  },
  {
    title: "群成员数量",
    dataIndex: "member_count",
  },
];

export default {
  props: {
    show_group: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data() {
    return {
      columns,
      selectedRowKeys: [],
      tableList: [],
      nick_name: "",
      bat_loading: false,
      tbl_loading: false,
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      selectedRowArr: [],
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    this.getGroupList(1, 10);
  },
  methods: {
    getGroupList(current, page_size) {
      let params = {
        nick_name: this.nick_name,
        page_num: current,
        page_size,
      };
      this.tbl_loading = true;
      this.$api.core.GroupChat.addGroupChat(params)
        .then((res) => {
          if (res.code == 200) {
            this.tableList = res.data.list;
            this.pagination = res.data.pagination;
          }
        })
        .finally(() => {
          this.tbl_loading = false;
        });
    },
    onSearch() {
      this.getGroupList(1, 10);
    },
    onChangePagination(current, page_size) {
      this.getGroupList(current, page_size);
    },
    // 找不到群聊提示
    openTips() {
      this.$info({
        title: "找不到群指引",
        content:
          "企微添加【18664824920】微信，将其拉入企微群聊中。三分钟后即可搜索找到企微群；如果仍然搜索不到，可在群里发送任意信息，一分钟后重试搜索。",
        onOk() {},
      });
    },
    returnTagDrawer() {
      this.selectedRowArr = [];
      this.$emit("closeGroupDrawer");
    },
    onSelectChange(selectedRowKeys, record) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowArr = JSON.parse(JSON.stringify(record));
    },
    addTag() {
      this.$emit("addNewGroup", this.selectedRowArr);
      this.bat_loading = true;
      setTimeout(() => {
        this.bat_loading = false;
        this.selectedRowKeys = [];
        this.selectedRowArr = [];
      }, 500);
    },
  },
};
</script>