var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"title":_vm.drawer_title,"placement":"right","closable":false,"visible":_vm.show_tag,"width":"580"},on:{"close":_vm.onClose}},[_c('span',[_vm._v(" 标签名称: "),_c('a-input',{staticStyle:{"width":"210px"},attrs:{"placeholder":"请输入"},model:{value:(_vm.tag_name),callback:function ($$v) {_vm.tag_name=$$v},expression:"tag_name"}})],1),_c('div',{staticStyle:{"margin":"12px 0"}},[_vm._v(" 群列表如下："),_c('a',{on:{"click":_vm.openGroup}},[_c('a-icon',{staticStyle:{"margin-right":"4px"},attrs:{"type":"plus-circle"}}),_vm._v("添加群聊")],1)]),_c('div',[_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('a-button',{attrs:{"type":"danger","disabled":!_vm.hasSelected,"loading":_vm.b_loading},on:{"click":_vm.deleteGroupMore}},[_vm._v(" 删除 ")]),_c('span',{staticStyle:{"margin-left":"8px"}},[(_vm.hasSelected)?[_vm._v(" "+_vm._s(("选择 " + (_vm.selectedRowKeys.length) + " 项"))+" ")]:_vm._e()],2),_c('span',{staticStyle:{"float":"right"}},[_vm._v("共"+_vm._s(_vm.table_list.length)+"个群聊")])],1),_c('a-table',{attrs:{"row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      },"columns":_vm.columns,"data-source":_vm.table_list,"scroll":{ y: 340 },"rowKey":function (record, index) { return index; },"loading":_vm.t_loading,"pagination":false,"locale":_vm.locale},scopedSlots:_vm._u([{key:"action",fn:function(text){return _c('div',{},[_c('a',{staticStyle:{"color":"red"},attrs:{"type":"link"},on:{"click":function($event){return _vm.deleteOneGroup(text)}}},[_vm._v("删除")])])}}])})],1),_c('div',{style:({
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e8e8e8',
      padding: '10px 16px',
      textAlign: 'right',
      left: 0,
      background: '#fff',
      borderRadius: '0 0 4px 4px',
    })},[_c('a-button',{staticStyle:{"margin-right":"8px"},on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.save_loading},on:{"click":_vm.addNewTag}},[_vm._v(" 确定 ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }